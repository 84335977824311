<template>
  <div style="width: 1920px;">
    <div class="banner" style="height: 935px;">
      <img src="../../images/activityListBanner.png" alt="">
    </div>
    <div class="bottom" v-loading="isLoading" style="padding-left: 278px;height: 2144px;">
      <div class="typeIndex" style="padding-top: 50px;margin-bottom:30px">
        <el-space>
          <div style="font-weight: 550;font-size: 30px">分类：</div>
          <div v-for="(item,i) in types" style="font-weight: 550;font-size: 25px">
            <div style="margin-left: 100px" @click="changeType(item.id)">{{item.name}}</div>
          </div>
          <div style="margin-left:100px;font-size: 20px;font-weight: 500;color: #ec414d" @click="this.$router.push('/SignActivity')">我要举办赛事</div>
        </el-space>
      </div>
      <el-divider style="width: 1346px"/>
        <el-space size="100" wrap style="margin-top: 30px">
          <div v-for="(item) in activities">
            <div class="activityCard" @click="jump(item)" style="width: 632px;height: 420px;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18), 0 5px 30px 0 rgba(0, 0, 0, 0.18);margin-right: 82px;margin-bottom: 62px;">
              <img :src="item.pic" alt="" style="width: 632px;height: 293px">
              <div class="cardInfo">
                <div class="cardInfoUp" style="padding-left: 50px;padding-top: 10px;padding-bottom: 13px;">
                  <div>
                    <div class="cardTitle" style="color: #0f0f0f;margin-bottom: 15px;font-size: 20px;font-weight: 450">
                      {{item.name}}
                    </div>
                    <div class="cardDate" style="color: #72767B99">
                      {{item.beginTime + '--' + item.endTime}}
                    </div>
                  </div>

                  <div class="icon" style="height: 0">
                    <img src="../../images/temp/已结束.png" alt="" style="margin-left: 423px;margin-top: -107px" v-show="item.status === 1">
                    <img src="../../images/temp/正在进行中.png" alt="" style="margin-left: 423px;margin-top: -107px" v-show="item.status === 0">
                  </div>

                </div>

                <el-divider />
                <div class="cardInfoDown" style="padding-left: 50px;padding-top: 8px;">
                  <div class="timeLeft" style="display: inline;margin-right: 400px;color: rgba(114,118,123,0.6)">
                    <img src="../../images/temp/时间.png" alt="" style="width: 11px;height: 11px;">
                    {{item.remainDays + '天'}}
                  </div>
                  <div class="views" style="display: inline;color: #72767B99">
                    <img src="../../images/temp/浏览量.png" alt="" style="width: 14px;height: 8px;">
                    {{item.clicks}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-space>
      <el-pagination
          background
          layout="prev, pager, next"
          :total="categoryForm.total"
          v-model:current-page="categoryForm.currentPage"
          v-model:page-size="categoryForm.size"
          style=";--el-pagination-hover-color: #ffffff;"/>
    </div>
  </div>

</template>

<script>
export default {
  name: "ActivityList",
  data(){
    return{
      activities:[],
      types:[],
      categoryForm:{
        currentPage:1,
        size:8,
        category:'',
        total:0,
      },
      isLoading:true,
    }
  },
  mounted() {
    this.getAllType();
    this.changeType(0)
  },
  methods:{
    getAllType(){
      this.types = [];
      this.isLoading = true;
      this.$http({
        url:'/activity/category/getAll',
        method:'get'
      }).then(({data}) => {
        this.types.push({
          id:0,
          name:'全部类别'
        })
        for(let i=0;i<data.data.length;i++){
            this.types.push(data.data[i]);
        }
        this.isLoading = false;
      })
    },
    changeType(type){
      this.isLoading = true;
      this.categoryForm.category = type;
      this.$http({
        method:'get',
        url:'/activity/searchByCategory?currentPage=' + this.categoryForm.currentPage + '&&size=8&&categoryId=' + type,
      }).then(({data})=>{

        this.activities = data.data.data;
        this.categoryForm.total = data.data.total;
        this.isLoading = false;
      })
    },
    jump(item){
      this.$http({
        method:'get',
        url:'/activity/visit?id=' + item.id
      }).then(res=>{

        window.location.href=item.url;
      })

    }
  }
}
</script>

<style scoped>
.el-divider--horizontal{
  margin: 0;
}
/*分页器样式部分start*/
::v-deep .el-pagination{
  padding-right: 30px;
  text-align: right;
}

::v-deep .el-pagination .btn-next{
  border-radius: 50%;
  padding: 9px;
}

::v-deep .el-pagination .btn-prev{
  border-radius: 50%;
  /*color: #ffffff;*/
  padding: 9px;
}

::v-deep .el-pager .active{
  background-color: #ec414d!important;
}

::v-deep .el-pager .number{

  border-radius: 50%!important;
  color: #212121;
}

::v-deep .el-pager .number:hover{
  background-color: #ec414d;
  color: #ffffff;
}


/*分页器样式部分end*/
</style>
